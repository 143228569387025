




























import { Component, Vue } from "vue-property-decorator";

type ExpansionPanel = {
  header: string;
  arrayOfText: { text: string | string[]; asList?: boolean }[];
};

@Component
class HelpCard extends Vue {
  listItems(text) {
    return text
  }
  panel1 = {
    header: "Как осуществить платеж картой?",
    arrayOfText: [
      {
        asList: true,
        text: [
          "Проверьте параметры платежа.",
          "Если параметры платежа корректны, то отметьте флажок о подтверждении параметров платежа.",
          "Нажмите на кнопку «Перейти к оплате».",
          "На странице банка введите данные банковской карты и смс-код подтверждения операции.",
        ],
      },
      {
        text: "Оплата производится банковскими картами платежных систем Мир, Visa Int, и MasterCard Worldwide.",
      },
    ],
  };

  panel2 = {
    header: "Как отменить платеж?",
    arrayOfText: [
      {
        text: "Для отмены проведенного платежа, обратитесь в офис «ПАО Сбербанк», где будет предложено написать заявление о возврате денежных средств.",
      },
    ],
  };

  panel3 = {
    header: "Безопасность платежей",
    arrayOfText: [
      {
        text: "Безопасность платежей обеспечивается с помощью Банка-эквайера, функционирующего на основе современных протоколов и технологий, разработанных международными платежными системами Visa International и MasterCard Worldwide (3D-Secure: Verified by VISA, MasterCard SecureCode).",
      },
      {
        text: "Обработка полученных конфиденциальных данных Держателя карты производится в процессинговом центре Банка-эквайера по закрытым сетям передачи данных, сертифицированным международными платежными системами для доставки конфиденциальной финансовой информации.",
      },
      {
        text: "Безопасность передаваемой информации обеспечивается с помощью современных протоколов обеспечения безопасности в Интернет.",
      },
    ],
  };

  panels: ExpansionPanel[] = [this.panel1, this.panel2, this.panel3];
}

export default HelpCard;
